/* ********************************************************************************************
    Design for a width of 1400px and above
*********************************************************************************************** */
/* ********************************************************************************************
    Design for a width of 768px and above
*********************************************************************************************** */
/* ********************************************************************************************
    Design for a width of 992px and above
*********************************************************************************************** */
/* ********************************************************************************************
    Design for a width of 12000px and above
*********************************************************************************************** */
/* ********************************************************************************************
    Design for a width of 1200px and below
*********************************************************************************************** */
/* ********************************************************************************************
    Design for a width of 1024px and below
*********************************************************************************************** */
/* ********************************************************************************************
    Design for a width of 992px and below
*********************************************************************************************** */
/* ********************************************************************************************
    Design for a width of 991px and below
*********************************************************************************************** */
/* ********************************************************************************************
    Design for a width of 767px and below
*********************************************************************************************** */
/* ********************************************************************************************
    Design for a width of 639px and below
*********************************************************************************************** */
/* ********************************************************************************************
    Design for a width of 500px and below
*********************************************************************************************** */
/* ********************************************************************************************
    Design for a width of 520px and below
*********************************************************************************************** */
/* ********************************************************************************************
    Design for a width of 479px and below
*********************************************************************************************** */
/* ********************************************************************************************
    Design for a width of 380px and below
*********************************************************************************************** */
/* ********************************************************************************************
    Design for a width of 320px and below
*********************************************************************************************** */
@media only screen and (min-width: 992px) {
	.navbar-expand-lg {
		.navbar-nav {
			.nav-link {
				padding: .8rem 1rem;
				font-size: 14px;
				text-transform: uppercase;
				font-weight: 500;
			}
		}
	}	
}
@media only screen and (max-width: 1024px) {
	.whatsapp-icon {
		bottom: 30px;
		right: 30px;
		height: 50px;
		width: 50px;
		font-size: 11px;
		line-height: 68px;
	}	
}
@media only screen and (max-width: 991px) { 
	.navbar-collapse .navbar-nav .dropdown-menu  {
		max-width: 200px !important;
		.dropdown-item {
			font-size: 13px;
		}
	}
}
@media only screen and (max-width: 767px) {
	body {
		font-size: 15px;
	}
	.h3 {
		font-size: 1.25rem;
	}
	h3 {
		font-size: 1.25rem;
	}
	.h5 {
		font-size: 1.125rem;
	}
	h5 {
		font-size: 1.125rem;
	}
	.font-size-16 {
		font-size: 15px;
	}
	.font-size-17 {
		font-size: 0.938rem;
	}
	.font-size-18 {
		font-size: 1rem;
	}
	.auth-box {
		padding: 20px;
	}
	.section-padding {
		padding: 40px 0px;
	}
	.page-title {
		font-size: 1.25rem;
		margin-bottom: 16px !important;
	}
	.navbar .collapse .navbar-nav .nav-item .btn {
		font-size: 14px;
	}
	.card-header {
		padding: 1.2rem;
	}
	.card-body {
		padding: 1.2rem;
	}
	.card-footer {
		padding: 1.2rem;
	}
	.form-control-lg {
		font-size: 15px;
		padding: .5rem 1.1rem;
	}
	.form-label-group {
		>label {
			top: 2px;
			font-size: 15px;
			height: 2.813rem;
			padding: .5rem 1.1rem;
		}
		>input {
			height: 2.813rem;
			padding: .5rem 1.1rem;
		}
		input {
			&:not(:placeholder-shown) {
				~ {
					label {
						left: 14px;
					}
				}
			}
		}
		.form-control-feedback {
			width: 45px;
			height: 45px;
			line-height: 45px;
			font-size: 15px;
		}
		>textarea {
			~ {
				label {
					left: 6px;
				}
			}
		}
		margin-bottom: 1.5rem;
	}
	.form-group {
		margin-bottom: 1.5rem;
	}
	.form-label-group.has-verified {
		.form-control {
			padding-right: 90px;
		}
		.form-control-feedback {
			width: 90px;
			font-size: 15px;
		}
	}
	.form-label-group.has-not-verified {
		.form-control {
			padding-right: 90px;
		}
		.form-control-feedback {
			width: 90px;
			font-size: 15px;
		}
	}
	.form-label-group.has-country-code  {
		.form-control {
			padding-left: 70px;
		}
		.form-control-feedback.country-code {
			width: 70px;
		}
	}
	.btn-group-sm {
		>.btn {
			font-size: 14px;
		}
	}
	.btn-sm {
		font-size: 14px;
	}
	.btn-group-lg {
		>.btn {
			font-size: 1rem;
		}
	}
	.btn-lg {
		font-size: 1rem;
	}
	.w-150 {
		width: auto;
	}
	.w-200 {
		width: auto;
	}
	.nav-tabs {
		.nav-link {
			min-width: 120px;
		}
	}
	.table {
		thead {
			th.font-size-18 {
				font-size: 1rem;
			}
		}
	}
	.price {
		font-size: 1.125rem;
	}
	.apply-btn {
		.apply-arrow {
			height: 45px;
			margin-right: -7px;
		}
		.btn {
			top: 0px;
			height: 45px;
			left: 5px;
			line-height: 30px;
		}
	}
	.select2-lg {
		.select2-container {
			.select2-selection--single {
				height: 45px;
			}
		}
		.select2-container--default {
			.select2-selection--single {
				.select2-selection__rendered {
					line-height: 44px;
					padding: 0rem 1.2rem;
				}
				.select2-selection__arrow {
					height: 45px;
					line-height: 45px;
				}
			}
		}
	}
	.select2-lg {
		.ng-select {
			.ng-placeholder {
				top: 10px;
				left: 13px;
			}
			.ng-select-container{
				padding: 0.5625rem 1.2rem;
				font-size: 1rem;
				&.ng-has-value{
					padding: 1rem 1.1rem 0.50rem;
				}
			}
			&:not(.ng-select-multiple){
				.ng-select-container{
					.ng-value-container .ng-input{
						padding: 0.625rem 2.7rem 0.625rem 1.5rem;
					}
					&.ng-has-value{
						.ng-value-container .ng-input{
							padding:1rem 2.7rem 0.50rem 1.1rem;
						}
					}
				}
			}
			// &.ng-select-opened{
			// 	.ng-select-container{
			// 		border-bottom-width: 0px;
			// 	}
			// }
		}
	}
	.my-account-tabs .nav-tabs {
		li {
			width: 100%;
		}
		>li {
			>a {
				padding: 17px 20px;
				text-align: left;
			}
		}
	}
	.custom-control-box-card.checkmark-checkbox {
		.custom-control-label {
			&::after {
				top: 21px;
			}
		}
	}
	.custom-control-box-card.checkmark-radio {
		.custom-control-label {
			&::after {
				top: 21px;
			}
		}
	}
	.notification-list {
		height: 250px;
	}
	.notification-menu {
		.dropdown-menu {
			width: 240px;
		}
	}
	.dropdown-menu {
		font-size: 15px;
	}
	.dropdown-item {
		font-size: 15px;
	}
	.filter {
		right: 10px;
		.dropdown-menu {
			width: 290px;
		}
	}
	.pac-target-input:not(:-webkit-autofill) {
		font-size: 15px;
	}
	.cms-page {
		p {
			line-height: 25px;
		}
	}

	.modal-header {
		padding: 1.5rem;
	}
	.modal-body {
		padding: 1.5rem;
	}
	.modal-footer {
		padding: 1.5rem;
	}
	.modal-open {
		.modal {
			padding-right: 0px !important;
		}
	}
	.inner-footer {
		padding-top: 45px;
		padding-bottom: 30px;
	}
	.accordion {
		.btn {
			font-size: 16px;
		}
	}
	.home-btn {
		height: 45px;
		line-height: 29px;
		width: 175px;
	}
	.faq-accordion {
		.card {
			.card-header {
				padding: 0.875rem 1.25rem;
			}
			.card-body {
				padding: 0.875rem 1.25rem;
			}
			.btn {
				font-size: 1rem !important;
			}
		}
	}
	.whatsapp-icon {
		bottom: 15px;
		right: 15px;
		height: 40px;
		width: 40px;
		font-size: 9px;
		line-height: 55px;
	}
}
@media only screen and (min-width: 576px) {
	.modal-sm {
		max-width: 400px;
	}
}
@media only screen and (max-width: 479px) {
	.show-on-desktop {
		display: none;
	}
	.show-on-mobile {
		display: block;
	}
}
