/*!
 * @copyright &copy; Kartik Visweswaran, Krajee.com, 2013 - 2015
 * @version 3.5.1
 *
 * A simple yet powerful JQuery star rating plugin that allows rendering
 * fractional star ratings and supports Right to Left (RTL) input.
 *
 * For more JQuery/Bootstrap plugins and demos visit http://plugins.krajee.com
 * For more Yii related demos visit http://demos.krajee.com
 */
/* .rating-loading {
    width: 25px;
    height: 25px;
    font-size: 0px;
    color: #fff;
    background: transparent url('loading.gif') top left no-repeat;
    border: none;
} */
/*
 * Stars
 */
.rating-fa {
    font-family: 'FontAwesome';
    padding-left: 1px;
}

.rating-fa .rating-stars:before {
    padding-left: 1px;
}

.rating-gly {
    font-family: 'Glyphicons Halflings';
}

.rating-gly-star {
    font-family: 'Glyphicons Halflings';
}

.rating-gly-star .rating-stars:before {
    /*padding-left: 2px;*/
}

.rating-lg .rating-gly-star, .rating-lg .rating-gly-star .rating-stars:before {
    /* padding-left: 4px; */
}

.rating-xl .rating-gly-star, .rating-xl .rating-gly-star .rating-stars:before {
    padding-left: 2px;
}

.rating-active {
    cursor: default;
}

.rating-disabled {
    cursor: not-allowed;
}

.rating-uni {
    font-size: 1.2em;
    margin-top: -5px;
}

.rating-container {
    width: 200px;
    height: 38px;
    font-size: 0;
    line-height: 0;
    background: url(ratings_large.png) 0 0 repeat-x;
    text-indent: -999em;
    overflow: hidden;
    margin: 0 auto;
}

.small-ratings .rating-container{
    width: 85px;
    height: 15px;
    font-size: 0;
    line-height: 0;
    background: url(ratings_large.png) 0 0 repeat-x;
    text-indent: -999em;
    overflow: hidden;
}

.rating-container .rating-stars {
    cursor: pointer;
    float: left;
    height: 38px;
    background: url(ratings_large.png) 0 100% repeat-x;
    background-color:transparent;
}
/**
 * Rating sizes
 */
.rating-xl {
    font-size: 4.89em;
}

.rating-lg {
    font-size: 3.91em;
}

.rating-md {
    font-size: 3.13em;
}

.rating-sm {
    font-size: 2.5em;
}

.rating-xs {
    font-size: 20px;
}

/**
 * Clear rating button
 */
.star-rating .clear-rating{
    color: #aaa;
    cursor: not-allowed;
    display: inline-block;
    vertical-align: middle;
    font-size: 60%;
}

.clear-rating-active {
    cursor: pointer !important;
}

.clear-rating-active:hover {
    color: #843534;
}

.star-rating .clear-rating {
    padding-right: 5px;
    display: none;
}

/**
 * Caption
 */
.star-rating .caption{
    color: #999;
    display: inline-block;
    vertical-align: middle;
    font-size: 55%;
}

.star-rating .caption {
    padding-left: 5px;
    display: none;
}
