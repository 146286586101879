@font-face {
  font-family: 'GTWalsheimPro';
  src: url('GT-Walsheim-Pro-Light.eot');
  src: url('GT-Walsheim-Pro-Light.eot?#iefix') format('embedded-opentype'),
       url('GT-Walsheim-Pro-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GTWalsheimPro';
  src: url('GTWalsheimProLight.svg#GTWalsheimProLight') format('svg'),
       url('GTWalsheimProLight.ttf') format('truetype'),
       url('GTWalsheimProLight.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GTWalsheimPro';
  src: url('GT-Walsheim-Pro-Regular.eot');
  src: url('GT-Walsheim-Pro-Regular.eot?#iefix') format('embedded-opentype'),
       url('GT-Walsheim-Pro-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GTWalsheimPro';
  src: url('GTWalsheimProRegular.svg#GTWalsheimProRegular') format('svg'),
       url('GTWalsheimProRegular.ttf') format('truetype'),
       url('GTWalsheimProRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GTWalsheimPro';
  src: url('GT-Walsheim-Pro-Medium.eot');
  src: url('GT-Walsheim-Pro-Medium.eot?#iefix') format('embedded-opentype'),
       url('GT-Walsheim-Pro-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GTWalsheimPro';
  src: url('GTWalsheimProMedium.svg#GTWalsheimProMedium') format('svg'),
       url('GTWalsheimProMedium.ttf') format('truetype'),
       url('GTWalsheimProMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'GTWalsheimPro';
  src: url('GT-Walsheim-Pro-Bold.eot');
  src: url('GT-Walsheim-Pro-Bold.eot?#iefix') format('embedded-opentype'),
       url('GT-Walsheim-Pro-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'GTWalsheimPro';
  src: url('GTWalsheimProBold.svg#GTWalsheimProBold') format('svg'),
       url('GTWalsheimProBold.ttf') format('truetype'),
       url('GTWalsheimProBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'GTWalsheimPro';
  src: url('GT-Walsheim-Pro-Ultra-Bold.eot');
  src: url('GT-Walsheim-Pro-Ultra-Bold.eot?#iefix') format('embedded-opentype'),
       url('GT-Walsheim-Pro-Ultra-Bold.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'GTWalsheimPro';
  src: url('GTWalsheimProUltraBold.svg#GTWalsheimProUltraBold') format('svg'),
       url('GTWalsheimProUltraBold.ttf') format('truetype'),
       url('GTWalsheimProUltraBold.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}


