/*!
 * Jasny Bootstrap v3.1.3 (http://jasny.github.io/bootstrap)
 * Copyright 2012-2014 Arnold Daniels
 * Licensed under Apache-2.0 (https://github.com/jasny/bootstrap/blob/master/LICENSE)
 */
 
.btn-file {
  position: relative;
  overflow: hidden;
  vertical-align: middle;
}
.btn-file > input {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 23px;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;
  direction: ltr;
}
.fileinput {
  display: block;
  position: relative;
}
.fileinput .form-control{
  line-height: 1.9;  
}
.fileinput .form-control,
.fileinput .input-group-append{
  cursor: pointer;
}
.fileinput .thumbnail {
  display: inline-block;
  margin-bottom: 0px;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  border-radius:  4px;
  background-color: #F9F9F9;
  border: 1px solid #e6e6e6;
}
.fileinput .thumbnail > img {
  width: 100%;
  height: 100%;
  object-fit:contain;
}
.fileinput .btn {
  vertical-align: middle;
}
.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none;
}
.fileinput-inline .fileinput-controls {
  display: inline;
}
.fileinput-filename {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
}
.form-control .fileinput-filename {
  vertical-align: bottom;
}
.fileinput.input-group {
  display: table;
}
.fileinput.input-group > * {
  position: relative;
  z-index: 2;
}
.fileinput.input-group > .btn-file {
  z-index: 1;
}
.fileinput-new.input-group .btn-file,
.fileinput-new .input-group .btn-file {
  border-radius: 0 4px 4px 0;
}
.fileinput-new.input-group .btn-file.btn-xs,
.fileinput-new .input-group .btn-file.btn-xs,
.fileinput-new.input-group .btn-file.btn-sm,
.fileinput-new .input-group .btn-file.btn-sm {
  border-radius: 0 3px 3px 0;
}
.fileinput-new.input-group .btn-file.btn-lg,
.fileinput-new .input-group .btn-file.btn-lg {
  border-radius: 0 6px 6px 0;
}
.form-group.has-warning .fileinput .fileinput-preview {
  color: #8a6d3b;
}
.form-group.has-warning .fileinput .thumbnail {
  border-color: #faebcc;
}
.form-group.has-error .fileinput .fileinput-preview {
  color: #a94442;
}
.form-group.has-error .fileinput .thumbnail {
  border-color: #ebccd1;
}
.form-group.has-success .fileinput .fileinput-preview {
  color: #3c763d;
}
.form-group.has-success .fileinput .thumbnail {
  border-color: #d6e9c6;
}
.input-group-addon:not(:first-child) {
  border-left: 0;
}
.camera-button{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    line-height: 250px;
    border-radius: 100%;
    transition: all 0.3s ease-in-out;
    font-size: 20px;
}
.custom-fileinput .camera-button {
  line-height: 100px;
}
.custom-fileinput .fileinput .thumbnail > img {
  object-fit: inherit;
}
.camera-button span {
  line-height: normal;
}
.remove-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
.camera-text {
  line-height: 320px;
}
/* .fileinput > .btn-file:hover .camera-button{
  opacity: 1;
  visibility: visible;
} */