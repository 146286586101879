/* ********************************************************************************************
    Reset
*********************************************************************************************** */
/* ********************************************************************************************
    Colors
*********************************************************************************************** */
/* ********************************************************************************************
    Buttons
*********************************************************************************************** */
/* ********************************************************************************************
    Form
*********************************************************************************************** */
/* ********************************************************************************************
    Form Label Group
*********************************************************************************************** */
/* Fallback for Edge
-------------------------------------------------- */
/* Fallback for IE
-------------------------------------------------- */
/* ********************************************************************************************
    Select2
*********************************************************************************************** */
/* ********************************************************************************************
    Modal
*********************************************************************************************** */
/* ********************************************************************************************
    Table
*********************************************************************************************** */
/* ********************************************************************************************
    Navbar
*********************************************************************************************** */
/* ********************************************************************************************
    Tabs
*********************************************************************************************** */
/* ********************************************************************************************
    Banner
*********************************************************************************************** */
/* ********************************************************************************************
    Section
*********************************************************************************************** */
/* ********************************************************************************************
    Homepage
*********************************************************************************************** */
/* ********************************************************************************************
    Sign In & Sign Up
*********************************************************************************************** */
/* ********************************************************************************************
    Inner Pages
*********************************************************************************************** */
/* ********************************************************************************************
    Custom Box Card and Checkbox
*********************************************************************************************** */
/* ********************************************************************************************
    Checkbox Container
*********************************************************************************************** */
/* ********************************************************************************************
    Accordian
*********************************************************************************************** */
/* ********************************************************************************************
    Pagination
*********************************************************************************************** */
/* ********************************************************************************************
    Footer
*********************************************************************************************** */
/* ********************************************************************************************
    Animation
*********************************************************************************************** */
html {
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}
body {
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  color: #56627f;
  font-weight: normal;
  font-family: "GTWalsheimPro";
}
a {
  color: #4c505a;
  text-decoration: none !important;
  -webkit-transition: color 0.3s ease-in-out, background 0.3s ease-in-out,
    opacity 0.3s ease-in-out, border 0.3s ease-in-out,
    font-weight 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out,
    opacity 0.3s ease-in-out, border 0.3s ease-in-out,
    font-weight 0.3s ease-in-out;
  &:hover {
    color: #ff9e2d;
  }
}
img {
  max-width: 100%;
}
.h1 {
  color: #152549;
  font-size: 3.125rem;
  font-weight: normal;
}
.h2 {
  color: #152549;
  font-size: 2.25rem;
  font-weight: 500;
}
.h3 {
  color: #152549;
  font-size: 1.625rem;
  font-weight: bold;
  text-transform: uppercase;
}
.h4 {
  color: #152549;
  font-size: 1.375rem;
  font-weight: 500;
}
.h5 {
  color: #152549;
  font-size: 1.25rem;
  font-weight: 500;
}
.h6 {
  color: #152549;
  font-size: 1rem;
  font-weight: 500;
}
h1 {
  color: #152549;
  font-size: 3.125rem;
  font-weight: normal;
}
h2 {
  color: #152549;
  font-size: 2.25rem;
  font-weight: 500;
}
h3 {
  color: #152549;
  font-size: 1.625rem;
  font-weight: bold;
  text-transform: uppercase;
}
h4 {
  color: #152549;
  font-size: 1.375rem;
  font-weight: 500;
}
h5 {
  color: #152549;
  font-size: 1.25rem;
  font-weight: 500;
}
h6 {
  color: #152549;
  font-size: 1rem;
  font-weight: 500;
}
.font-size-12 {
  font-size: 0.75rem;
}
.font-size-14 {
  font-size: 0.875rem;
}
.font-size-16 {
  font-size: 1rem;
}
.font-size-17 {
  font-size: 1.063rem;
}
.font-size-18 {
  font-size: 1.125rem;
}
.font-size-24 {
  font-size: 1.5rem;
}
.font-size-26 {
  font-size: 1.625rem;
}
.font-weight-medium {
  font-weight: 500 !important;
}
.text-underline {
  text-decoration: underline !important;
}
.border {
  border: 1px solid #ebebeb !important;
}
.border-top {
  border-top: 1px solid #ebebeb !important;
}
.border-bottom {
  border-bottom: 1px solid #ebebeb !important;
}
.border-success {
  border: 1px solid #4db42f;
}
.circle {
  border-radius: 100%;
  object-fit: cover;
}
.w-150 {
  width: 150px;
}
.w-200 {
  width: 200px;
}
.lh-normal {
  line-height: normal;
}
.opacity-20 {
  opacity: 0.2;
}
.mt-n-4 {
  margin-top: -4px;
}
.m-t-60 {
  margin-top: 60px !important;
}
.text-muted {
  color: #a0a9be !important;
}
.text-dark {
  color: #0d2d53 !important;
}
.text-default {
  color: #56627f !important;
}
.text-primary {
  color: #22a2d7 !important;
}
a.text-primary {
  &:hover {
    color: #ff9e2d !important;
  }
}
.text-secondary {
  color: #ff9e2d !important;
}
.text-yellow {
  color: #fceb06;
}
.text-blue {
  color: #3a5896;
}
.text-success {
  color: #2ad552 !important;
}
.text-warning {
  color: #ff9e2d !important;
}
.text-custom {
  color: #152549 !important;
}
.text-custom-light {
  color: #777777 !important;
}
.bg-light {
  background-color: #f8faff !important;
}
.bg-secondary {
  background-color: #ff9e2d !important;
}
.bg-main {
  background-color: #f9f9f9;
}
.bg-blue {
  background-color: #3a5896;
}
.bg-success {
  background-color: rgba(77, 180, 47, 0.05) !important;
}
.pointer {
  cursor: pointer;
}
.btn {
  outline: none !important;
  box-shadow: none !important;
  font-weight: 400;
  padding-left: 1rem;
  padding-right: 1rem;
  -webkit-transition: color 0.3s ease-in-out, background 0.3s ease-in-out,
    opacity 0.3s ease-in-out, border 0.3s ease-in-out,
    font-weight 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out,
    opacity 0.3s ease-in-out, border 0.3s ease-in-out,
    font-weight 0.3s ease-in-out;
}
.btn-group-sm {
  > .btn {
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: normal;
    padding: 0.25rem 0.75rem 0.18rem 0.75rem !important;
  }
}
.btn-sm {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: normal;
  padding: 0.25rem 0.75rem 0.18rem 0.75rem !important;
}
.btn-group-lg {
  > .btn {
    font-size: 1.125rem;
    font-weight: 500 !important;
    padding: 0.5625rem 1rem;
    border-radius: 0.125rem;
    text-transform: uppercase;
    line-height: normal;
  }
}
.btn-lg {
  font-size: 1.125rem;
  font-weight: 500 !important;
  padding: 0.5625rem 1rem;
  border-radius: 0.125rem;
  text-transform: uppercase;
  line-height: normal;
}
.btn-block + .btn-block {
  margin-top: 0.25rem;
}
.btn-primary-theme {
  background-color: #22a2d7;
  color: #fff;
  &:hover {
    background-color: #148fc1;
    color: #fff;
  }
  &:disabled {
    background-color: #9fbecb;
    &:hover {
      background-color: #9fbecb;
    }
  }
}
.btn-primary-theme-outline {
  border-color: #22a2d7;
  color: #22a2d7;
  &:hover {
    background-color: #22a2d7;
    color: #fff;
  }
}
.btn-secondary-theme-outline {
  background-color: #ffffff;
  border: 1px solid #56627f;
  color: #56627f;
  &:hover {
    background-color: #56627f;
    color: #fff;
  }
}
.btn-primary-theme.disabled {
  background-color: #9fbecb;
  &:hover {
    background-color: #9fbecb;
  }
}
.btn-dark-theme {
  background-color: #152549;
  color: #fff;
  &:hover {
    background-color: #101f40;
    color: #fff;
  }
}
.btn-link {
  color: #152549;
  text-decoration: none !important;
  &:hover {
    color: #22a2d7;
  }
}
.form-row {
  margin-left: -7.5px;
  margin-right: -7.5px;
  > .col {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  > [class*="col-"] {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}
.form-group {
  margin-bottom: 1.625rem;
  .select2-container {
    width: 100% !important;
  }
}
.form-control,
.custom-select {
  font-weight: 400;
  color: #56627f;
  border-radius: 0px;
  border-color: #e6e6e6;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0.125rem;
  &:focus {
    border-color: #ff9e2d;
    color: #152549;
  }
}
.form-control-lg,
.custom-select-lg {
  height: calc(2em + 1rem + 2px);
  padding: 0.5rem 1.5rem;
  font-size: 1.063rem;
}
textarea.form-control {
  min-height: 70px;
  resize: none;
}
input[type="number"] {
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.form-control .fileinput-filename {
  max-width: 170px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form-control .fileinput-filename {
  margin-left: 5px;
}

.input-group-text {
  outline: none !important;
  background-color: #fff;
  border-color: #e6e6e6;
  color: #152549;
  border-radius: 0px;
  border-left-color: #fff;
  z-index: 1;
  margin-left: -1px;
}
.form-label-group {
  position: relative;
  margin-bottom: 1.625rem;
  > input {
    height: 3.125rem;
    padding: 0.5rem 1.5rem;
  }
  > textarea {
    height: 3.125rem;
    padding: 0.5rem 1.5rem;
    ~ {
      label {
        top: 0px;
      }
    }
  }
  > label {
    height: 3.125rem;
    padding: 0.5rem 1.5rem;
    position: absolute;
    top: 4px;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    line-height: 1.5;
    color: #a6aab5;
    pointer-events: none;
    cursor: text;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: all 0.1s ease-in-out;
    font-size: 1.063rem;
  }
  input {
    &::-webkit-input-placeholder {
      color: #a6aab5;
    }
    &:-ms-input-placeholder {
      color: #a6aab5;
    }
    &::-ms-input-placeholder {
      color: #a6aab5;
      color: #a6aab5;
    }
    &::-moz-placeholder {
      color: #a6aab5;
    }
    &::placeholder {
      color: #a6aab5;
    }
    &:not(:placeholder-shown) {
      padding-top: 0.75rem;
      padding-bottom: 0.25rem;
      ~ {
        label {
          padding: 0.25rem 0.3rem 0rem;
          font-size: 14px;
          color: rgba(86, 98, 127, 0.8);
          top: -15px;
          left: 20px;
          height: auto;
          width: auto;
          background: #fff;
        }
      }
      &:focus {
        ~ {
          label {
            color: #ff9e2d;
          }
        }
      }
    }
  }
  textarea {
    &:not(:placeholder-shown) {
      padding-top: 0.75rem;
      padding-bottom: 0.25rem;
      ~ {
        label {
          padding: 0.25rem 0.3rem 0rem;
          font-size: 14px;
          color: rgba(86, 98, 127, 0.8);
          top: -15px;
          left: 20px;
          height: auto;
          width: auto;
          background: #fff;
        }
      }
      &:focus {
        ~ {
          label {
            color: #ff9e2d;
          }
        }
      }
    }
    &::-ms-input-placeholder {
      color: #a6aab5;
    }
  }
  .form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: 60px;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    color: #a0a9be;
    text-align: center;
  }
}
.form-label-group.has-text {
  .form-control-feedback {
    cursor: auto;
    color: #152549;
  }
  .form-control {
    padding-right: 60px;
  }
}
.form-label-group.has-country-code {
  .form-control-feedback.country-code {
    width: 100px;
    cursor: auto;
    left: 0px;
  }
  .form-control {
    padding-left: 100px;
  }
}
.form-label-group.has-verified {
  .form-control-feedback {
    width: 100px;
    cursor: auto;
  }
  .form-control {
    padding-right: 100px;
  }
}
.form-label-group.has-not-verified {
  .form-control-feedback {
    width: 100px;
    cursor: auto;
    cursor: pointer;
  }
  .form-control {
    padding-right: 100px;
  }
}
.form-label-group.has-date {
  .form-control-feedback {
    width: 100px;
    cursor: auto;
  }
}
.form-label-group.has-password-icon {
  .form-control {
    padding-right: 60px;
  }
}
.form-label-group.page-textarea {
  textarea {
    height: auto;
  }
}
.street-name label {
  font-size: 16px;
}
.select2-container--default {
  .select2-selection--single {
    .select2-selection__placeholder {
      color: #a6aab5;
    }
    .select2-selection__rendered {
      line-height: 38px;
      color: #56627f;
      padding: 0rem 0.75rem;
      font-size: 1.063rem;
      font-weight: 400;
    }
    .select2-selection__arrow {
      height: 38px;
      right: 8px;
      b {
        border-color: #a8afbe transparent transparent transparent;
      }
    }
  }
  .select2-search--dropdown {
    .select2-search__field {
      border: 1px solid #e6e6e6;
      border-radius: 6px;
      padding: 6px 10px;
      outline: none;
      border: 1px solid #eceeef;
      padding-left: 40px;
      font-size: 16px;
    }
  }
  .select2-results__option--highlighted[aria-selected] {
    background-color: #22a2d7 !important;
  }
  .select2-results__option[aria-selected="true"] {
    background: #f9f9f9;
  }
}
.select2-search--dropdown {
  &:before {
    font: normal normal normal 18px/1 "Material-Design-Iconic-Font";
    font-weight: 600;
    content: "\f1c3";
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    position: absolute;
    color: rgba(55, 58, 60, 0.5);
  }
  display: block;
  padding: 10px 20px;
  background-color: #f9f9f9;
}
.select2-container {
  .select2-selection--single {
    height: 38px;
    border: 1px solid #e6e6e6;
    border-radius: 0px;
    &:focus {
      outline: none !important;
    }
  }
}
.select2-dropdown {
  border: 1px solid #e6e6e6;
  border-radius: 0px;
  z-index: 999;
}
.select2-results__option[aria-selected] {
  color: #56627f;
}
.select2-results__option {
  padding: 6px 20px;
  font-size: 1rem;
}
.select2-lg {
  .select2-container {
    .select2-selection--single {
      height: 48px;
    }
    width: 100% !important;
  }
  .select2-container--default {
    .select2-selection--single {
      .select2-selection__rendered {
        line-height: 48px;
        padding: 0rem 1.5rem;
      }
      .select2-selection__arrow {
        height: 48px;
        line-height: 48px;
      }
    }
  }
}
.select2-nosearch {
  width: 100px !important;
  ~ {
    .select2-container {
      width: 100px !important;
    }
  }
}

/* NgSelect */
.ng-select {
  .ng-select-container {
    border: 1px solid #e6e6e6;
    background-color: #fff;
    color: #56627f;
    position: relative;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    font-size: 1.063rem;
    font-weight: normal;
    border-radius: 0.125rem;
    overflow: initial !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  &.ng-select-focused {
    .ng-select-container {
      border-color: #ff9e2d;
      color: #152549;
      &.ng-has-value .ng-placeholder {
        color: #ff9e2d;
      }
    }
  }
}
.ng-select:not(.ng-select-multiple)
  .ng-select-container
  .ng-value-container
  .ng-input {
  /* width: 100%; */
  top: 0;
  padding: 0.375rem 2.7rem 0.375rem 0.75rem;
}
.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  vertical-align: 1px;
}
.ng-select.ng-select-opened .ng-arrow-wrapper .ng-arrow {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.ng-dropdown-panel .ng-dropdown-panel-items {
  border: 1px solid #e6e6e6;
  background-color: #fff;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: 1rem;
  padding: 0.375rem 1.5rem;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: #f9f9f9;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #22a2d7;
  color: #fff;
}
.ng-select .ng-clear-wrapper {
  display: none;
}
.ng-select .ng-clear-wrapper .ng-clear {
  font-family: Arial, Helvetica, sans-serif;
  vertical-align: -2px;
}
.ng-select .ng-placeholder {
  color: #a6aab5;
  position: absolute;
  top: 13px;
  transition: all 0.1s ease-in-out;
}
.ng-select .ng-has-value .ng-placeholder {
  // display: none;
  padding: 0.25rem 0.3rem 0rem;
  font-size: 14px;
  color: rgba(86, 98, 127, 0.8);
  top: -15px !important;
  left: 20px;
  height: auto;
  width: auto;
  background: #fff;
  position: absolute;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  padding: 0px;
}
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  border: 1px solid #dfe7f3;
  background-color: #f0f6ff;
  padding: 0 5px;
  margin: 2.5px 5px 2.5px 0;
  border-radius: 4px;
}
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon {
  font-family: Arial, Helvetica, sans-serif;
  margin-right: 2px;
}
.input-group > .ng-select {
  position: relative;
  /* flex: 1 1 auto; */
  width: 1%;
  margin-bottom: 0;
}
.input-group .ng-select:not(:first-child) .ng-select-container {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}
.input-group .ng-select:not(:last-child) .ng-select-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.ngselect2-modal .ng-dropdown-panel {
  position: relative;
}

.select2-lg {
  .ng-select {
    .ng-select-container {
      padding: 0.75rem 1.5rem;
      &.ng-has-value {
        padding: 1rem 1.5rem 0.5rem;
      }
    }
    &:not(.ng-select-multiple) {
      .ng-select-container {
        .ng-value-container .ng-input {
          padding: 0.75rem 2.7rem 0.75rem 1.5rem;
        }
        &.ng-has-value {
          .ng-value-container .ng-input {
            padding: 1rem 2.7rem 0.5rem 1.5rem;
          }
        }
      }
    }
    &.ng-select-opened {
      .ng-select-container {
        border-bottom-width: 0px;
      }
    }
  }
}

.modal-content {
  border: none;
}
.modal-title {
  font-weight: 700;
}
.modal-header {
  padding: 2rem;
}
.modal-body {
  padding: 2rem;
}
.modal-footer {
  padding: 2rem;
}
.modal-backdrop {
  background-color: #152549;
}
.modal-backdrop.show {
  opacity: 0.6;
}

.close {
  font-size: 35px;
  color: #56627f;
  padding-right: 5px !important;
  &:focus {
    outline: none;
  }
}
.pac-container {
  z-index: 9999 !important;
}
// .pac-target-input:not(:-webkit-autofill) {
// 	font-size: 1.063rem;
// }
.table-shadow {
  box-shadow: 0px 3px 6px #00000029;
}
.table-border {
  .table {
    border: 1px solid #dee2e6;
  }
}
.table {
  thead {
    th {
      background-color: #f9f9f9;
      font-weight: 500;
      border-width: 0px;
      color: #152549;
      font-size: 1rem;
    }
    th.font-size-18 {
      font-size: 1.125rem;
    }
  }
  tbody {
    tr {
      &:first-child {
        td {
          border-top-width: 0px;
        }
      }
    }
  }
  color: #0d2d53;
  td {
    padding: 0.9rem 1.5rem;
    border-color: #e6eaed;
    vertical-align: middle;
  }
  th {
    padding: 0.9rem 1.5rem;
    border-color: #e6eaed;
    vertical-align: middle;
  }
}
.table.align-top {
  td {
    vertical-align: top;
  }
  th {
    vertical-align: top;
  }
}
.remove-left-right-padding {
  tr {
    th {
      &:first-child {
        padding-left: 0px;
      }
    }
    td {
      &:first-child {
        padding-left: 0px;
      }
    }
  }
  th {
    &:last-child {
      padding-right: 0px;
    }
  }
  td {
    &:last-child {
      padding-right: 0px;
    }
  }
}
.today-price {
  .table {
    thead {
      th {
        background-color: transparent;
        color: #fceb06;
        font-weight: normal;
      }
    }
    color: rgba(255, 255, 255, 0.8);
    td {
      padding: 0.6rem 1.5rem;
    }
    th {
      padding: 0.6rem 1.5rem;
    }
  }
}
.price {
  font-size: 1.25rem;
}
.badge {
  font-weight: normal;
  padding: 0.35em 0.4em;
}
.badge-info {
  background-color: #e4f9ff;
  color: #2dd4ff;
}
.badge-warning {
  background-color: #fff4e6;
  color: #ff9e2d;
}
.badge-warning-dark {
  background-color: #ff9e2d;
  color: #fff;
}
.badge-success {
  background-color: #ebffeb;
  color: #45d24a;
}
.badge-secondary {
  background-color: rgb(108 117 125 / 0.1);
  color: #545454;
}
.badge-danger {
  background-color: #fff6f3;
  color: #ff7f5e;
}
.badge-warning.bg-opacity {
  background-color: rgba(255, 158, 45, 0.2);
}
.navbar {
  border-bottom: 1px solid #00000017;
  .nav-link {
    &:not(:hover) {
      color: #1f1a17;
    }
  }
  .notification {
    > a {
      &:not(:hover) {
        color: #1f1a17;
      }
    }
  }
  .navbar-toggler-icon {
    &:before {
      content: "\f1ce";
      font-family: "Material-Design-Iconic-Font";
      font-size: 2rem;
    }
  }
  .user-menu {
    img {
      border-radius: 100%;
      object-fit: cover;
    }
    .dropdown-menu {
      width: 290px;
      padding: 0px;
      top: 125%;
    }
    .dropdown-item {
      padding: 0.8rem 1.5rem;
      border-bottom: 1px solid #ebebeb;
    }
  }
}
.navbar-toggler {
  border-color: transparent;
  padding-left: 0;
  padding-right: 1rem;
  outline: none !important;
}
.button-right {
  a.btn {
    font-size: 15px;
  }
  .dropdown-item {
    font-size: 15px;
  }
}
.notification-menu {
  .dropdown-menu {
    width: 320px;
    padding: 0px;
    top: 125%;
  }
}
.notification-list {
  height: 322px;
  overflow-y: auto;
  li {
    &:last-child {
      border-bottom: none !important;
      padding-bottom: 0px !important;
    }
  }
}
.filter {
  .dropdown-menu {
    width: 320px;
  }
  position: absolute;
  top: -45px;
  right: 30px;
}
.noti-count {
  position: absolute;
  top: -7px;
  right: -2px;
  text-align: center;
  height: 18px;
  width: 18px;
  line-height: 17px;
  border-radius: 50%;
  background: #ff9e2d;
  color: #fff;
  font-size: 11px;
  border: 1px solid #ffffff;
}
.read {
  opacity: 0.6;
  .text-warning {
    color: #a2a2a2 !important;
  }
}
.date {
  color: #a2a2a2;
  font-size: 15px;
}
.show-on-mobile {
  display: none;
}
.dropdown-menu-grid {
  .box {
    text-align: center;
    font-size: 12px;
    color: rgba(46, 58, 89, 0.75);
    padding: 15px 5px;
    display: block;
    width: 100%;
    i {
      font-size: 25px;
    }
  }
}
.nav-tabs {
  background-color: #fff;
  border-color: #ebebeb;
  .nav-item {
    margin-bottom: 0px;
  }
  .nav-link {
    // color: #8A8E9A;
    padding: 0.969rem 1rem;
    min-width: 160px;
    text-align: center;
    background: #ffffff;
    border-radius: 0;
    margin-right: -1px;
    position: relative;
    border: none;
    // border-bottom: 3px solid transparent;
    &:focus {
      color: #ff9e2d;
      border-color: #ff9e2d;
    }
    &:hover {
      color: #ff9e2d;
      border-color: #ff9e2d;
    }
  }
  .nav-item.show {
    .nav-link {
      color: #ff9e2d;
      border-bottom: 3px solid #ff9e2d;
    }
  }
  .nav-link.active {
    color: #ff9e2d;
    border-bottom: 3px solid #ff9e2d;
  }
}
.tab-content {
  background-color: #fff;
}
.banner {
  position: relative;
  .item-image {
    position: relative;
    height: 535px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(28, 33, 35, 0.8);
    }
  }
}
.banner-content {
  position: absolute;
  left: 0;
  width: 100%;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
   -webkit-transform: translateY(-50%);
  p {
    font-weight: normal;
    line-height: normal;
  }
}

.carousel-indicators li {
	width: 10px;
    height: 10px;
    margin: 5px;
    background: transparent;
    border: 1px solid #fff !important;
	border-radius: 30px;
	opacity: 1;
}
.carousel-indicators li.active {
    background: #fff;
}
.carousel-control {
	display: none;
}
.content {
  min-height: calc(100vh - 446px);
}
.section-padding {
  padding: 70px 0px;
}
.section-padding.small-top {
  padding-top: 40px;
}
.max-width-900 {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.max-width-700 {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}
.max-width-600 {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.max-width-370 {
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
}
.page-header {
  padding-bottom: 15px;
}
.card {
  border: none;
}
.card-header {
  background-color: transparent;
  border-color: transparent;
  padding: 1.5rem;
}
.card-body {
  padding: 1.5rem;
}
.card-footer {
  background-color: transparent;
  border-color: transparent;
  padding: 1.5rem;
}
.page-title {
  font-size: 1.5rem;
  margin-bottom: 24px !important;
}
.page-title.mb-0 {
  margin-bottom: 0px !important;
}
.equal-height {
  min-height: calc(100% - 1.5rem);
}
.icon-box {
  .icon {
    font-size: 40px;
    width: 88px;
    height: 88px;
    line-height: 88px;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}
.icon-1 {
  .icon {
    background-color: #e5eeff;
    color: #3a5896;
  }
  .heading {
    color: #3a5896;
  }
}
.icon-2 {
  .icon {
    background-color: #dcf5ff;
    color: #22a2d7;
  }
  .heading {
    color: #22a2d7;
  }
}
.icon-3 {
  .icon {
    background-color: #ffead1;
    color: #ff9e2d;
  }
  .heading {
    color: #ff9e2d;
  }
}
.home-cta {
  background-color: #3a5896;
  border-radius: 0.5rem;
  padding: 25px 30px;
}
.download {
  position: relative;
  z-index: 1;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 210px;
    background: url(assets/img/download.png) no-repeat;
    background-position: bottom center;
    background-repeat: repeat-x;
    z-index: -1;
    opacity: 0.35;
  }
}
.contact-section {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(249, 249, 249, 1) 100%
  );
}
.auth-box {
  max-width: 450px;
  margin: 0 auto;
  background-color: #fdfdfd;
  border: 1px solid #ececec;
  padding: 40px;
}
.my-account-tabs .nav-tabs {
  > li {
    > a {
      padding: 17px 25px;
      display: block;
      // border-bottom: 3px solid transparent;
      transition: all 0.3s;
      .zmdi {
        color: #8a8e9a;
        margin-right: 7px;
      }
      &:hover {
        color: #ff9e2d;
        .zmdi {
          color: #ff9e2d;
        }
      }
    }
  }
  > li.active {
    > a {
      color: #ff9e2d;
      border-bottom: 3px solid #ff9e2d;
      .zmdi {
        color: #ff9e2d;
      }
    }
  }
  .list-inline-item {
    &:not(:last-child) {
      margin-right: 0rem;
    }
  }
}
.list-group-item {
  border-color: #e6eaed;
}
.otp-box {
  font-size: 1.5rem;
  height: 54px;
  text-align: center;
}
.truck-box {
  position: relative;
  .dropdown {
    position: absolute;
    right: -12px;
    z-index: 1;
    top: 14px;
  }
}
.apply-btn {
  width: 88px !important;
  .btn {
    position: relative;
    height: 50px;
    line-height: 34px;
  }
  .apply-arrow {
    border: 1px solid #22a2d7 !important;
    display: inline-block;
    height: 50px;
  }
}
.apply-btn.d-flex {
  right: -5px;
}
.apply-btn.d-flex.remove-apply-btn {
  right: 16px;
}
.home-btn {
  height: 52px;
  line-height: 35px;
  width: 225px;
}
.dropdown-header {
  border-bottom: 1px solid #e4e9f2;
}
.dropdown-menu {
  border: none;
  box-shadow: 0 1px 6px rgba(34, 43, 69, 0.16);
}
.dropdown-item {
  color: #4b4b4b;
  padding: 0.7rem 1.5rem;
  text-transform: uppercase;
  cursor: pointer;
  &:focus {
    background-color: #fffbf6;
    color: #4c505a;
  }
  &:hover {
    background-color: #fffbf6;
    color: #4c505a;
  }
  &:active {
    background-color: #ff9e2d;
    color: #fff;
  }
}
.dropdown-item.active {
  background-color: #ff9e2d;
  color: #fff;
}
.filter-position {
  position: relative !important;
  top: 0px !important;
  right: 0px !important;
}
.view-label {
  color: rgba(86, 98, 127, 0.8);
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}
.login-fileinput {
  border-radius: 0 0.125rem 0.125rem 0 !important;
  border-left: 1px solid #22a2d7 !important;
}
.login-fileinput.btn-secondary-theme-outline {
  border-left: 1px solid #56627f !important;
}
.alert {
  font-size: 1.125rem;
  margin-bottom: 1.5rem;
}
.alert-success {
  color: #218d48;
  background-color: #dbffe8;
  border-color: #dbffe8;
}
.cms-page {
  p {
    line-height: 30px;
  }
}
.custom-control-box {
  padding-left: 0px;
  border-width: 0px;
  position: relative;
  z-index: 1;
  .badge {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.15s ease-in-out, visibility 0.15s ease-in-out;
  }
  .custom-control-label {
    cursor: pointer;
    &::before {
      outline: none !important;
      box-shadow: none !important;
      z-index: -1;
    }
    &:hover {
      &:before {
        border-color: #ff9e2d;
      }
    }
    .heading {
      transition: color 0.15s ease-in-out;
    }
  }
  &:not(.custom-control-box-card) {
    .custom-control-label {
      &::before {
        top: 0;
        left: 0;
        width: auto;
        height: auto;
        right: 0;
        bottom: 0;
        border-radius: 0.25rem;
        border-color: rgba(160, 169, 190, 0.3);
      }
    }
  }
  .custom-control-input {
    &:not(:disabled) {
      &:active ~ .custom-control-label {
        &::before {
          border-color: #ff9e2d;
          background-color: #fffbf6;
        }
      }
    }
    &:checked ~ .custom-control-label {
      &::before {
        border-color: #ff9e2d;
        background-color: #fffbf6;
      }
      .badge {
        visibility: visible;
        opacity: 1;
      }
      .heading {
        color: #ff9e2d;
      }
    }
  }
}
.custom-control-box-card {
  border-width: 1px;
  .custom-control-label {
    padding-left: 3rem;
    border: 1px solid rgba(160, 169, 190, 0.3);
    border-radius: 4px;
    &::before {
      left: 0.75rem;
      top: 0.75rem;
      border-color: #56627f !important;
      border-width: 2px;
    }
    &::after {
      left: 0.75rem;
      top: 0.75rem;
      background: #56627f;
      background-image: none !important;
      border-radius: 100%;
      width: 8px;
      height: 8px;
      left: 1rem;
      top: 1rem;
      visibility: hidden;
      opacity: 0;
    }
  }
  .custom-control-input {
    &:checked ~ .custom-control-label {
      &::before {
        border-color: #56627f;
      }
      &::after {
        visibility: visible;
        opacity: 1;
      }
      .form-inline {
        pointer-events: all;
      }
    }
  }
  .dropdown {
    position: absolute;
    right: 10px;
    z-index: 1;
    top: 10px;
  }
  .form-inline {
    pointer-events: none;
  }
}
.custom-control-dropdown-menu {
  .dropdown {
    position: absolute;
    right: 10px;
    z-index: 1;
    top: 10px;
    top: 16px;
    a {
      i {
        font-size: 22px;
        color: #737781;
      }
    }
  }
}
.custom-control-box-card.checkmark-checkbox {
  .custom-control-label {
    &::before {
      left: 1.1rem;
      top: 1.2rem;
    }
    &::after {
      left: 1.1rem;
      top: 1.2rem;
      left: 22.5px;
      top: 21px;
      width: 5px;
      height: 10px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      border-radius: 0px;
      background: transparent;
      z-index: 1;
    }
  }
}
.custom-control-box-card.checkmark-radio {
  .custom-control-label {
    &::before {
      left: 1.1rem;
      top: 1.2rem;
    }
    &::after {
      left: 1.1rem;
      top: 1.2rem;
      left: 22.5px;
      top: 21px;
      width: 5px;
      height: 10px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      border-radius: 0px;
      background: transparent;
      z-index: 1;
    }
  }
}
.custom-control-box.checkmark-checkbox {
  .custom-control-input {
    &:checked ~ .custom-control-label {
      &::before {
        background-color: #ff9e2d;
        border-color: #ff9e2d !important;
        z-index: 1;
      }
    }
  }
}
.custom-control-box.checkmark-radio {
  .custom-control-input {
    &:checked ~ .custom-control-label {
      &::before {
        background-color: #ff9e2d;
        border-color: #ff9e2d !important;
        z-index: 1;
      }
    }
  }
}
.checkmark-checkbox.custom-control-box-card {
  .custom-control-input {
    ~ {
      .custom-control-label {
        border: 1px solid #ebebeb;
        border-radius: 4px;
      }
    }
    &:checked ~ .custom-control-label {
      background-color: #fffbf6;
      border: 1px solid #ff9e2d;
    }
  }
}
.checkmark-radio.custom-control-box-card {
  .custom-control-input {
    ~ {
      .custom-control-label {
        border: 1px solid #ebebeb;
        border-radius: 4px;
      }
    }
    &:checked ~ .custom-control-label {
      background-color: #fffbf6;
      border: 1px solid #ff9e2d;
    }
  }
}
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 26px;
  color: rgba(21, 37, 73, 0.6);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: inherit;
  margin-bottom: 0px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ {
        .checkmark {
          background-color: #ff9e2d;
          border: 2px solid #ff9e2d;
          &::after {
            display: block;
          }
        }
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 100%;
    background-color: #fff;
    border: 2px solid #b8b7b7;
    &::after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: 0px;
      width: 6px;
      height: 11px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
.table-checkbox .checkbox-container .checkmark {
  top: 50%;
  transform: translateY(-50%);
  border-radius: 3px;
}
.radio-container {
  display: block;
  position: relative;
  padding-left: 26px;
  color: rgba(21, 37, 73, 0.6);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: inherit;
  margin-bottom: 0px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ {
        .checkmark {
          background-color: #ff9e2d;
          border: 2px solid #ff9e2d;
          &::after {
            display: block;
          }
        }
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 100%;
    background-color: #fff;
    border: 2px solid #b8b7b7;
    border-radius: 100%;
    &::after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: 0px;
      width: 6px;
      height: 11px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
.accordion {
  .card-header {
    padding: 0px;
  }
  .btn {
    color: #282f39;
    padding: 15px 20px 15px 20px;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    text-decoration: none;
    display: block;
    width: 100%;
    text-transform: capitalize;
    position: relative;
    &:before {
      font-family: "Material-Design-Iconic-Font";
      font-size: 27px;
      font-weight: 500;
      content: "\f2f9";
      transition: all 0.3s;
      position: absolute;
      right: 25px;
      top: 50%;
      -webkit-transform: rotate(0deg) translateY(-50%);
      -moz-transform: rotate(0deg) translateY(-50%);
      transform: rotate(0deg) translateY(-50%);
    }
    &:hover {
      color: #22a2d7;
    }
    // &:not(.collapsed) {
    // 	color: #22A2D7;
    // }
  }
  // .btn.collapsed {
  // 	&:before {
  // 		-webkit-transform: rotate(0deg) translateY(-50%);
  // 		-moz-transform: rotate(0deg) translateY(-50%);
  // 		transform: rotate(0deg) translateY(-50%);
  // 	}
  // }
  .panel-open {
    .btn {
      color: #22a2d7;
      &:before {
        -webkit-transform: translateY(-50%) rotate(180deg);
        -moz-transform: translateY(-50%) rotate(180deg);
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
  .panel-heading {
    border-bottom: 1px solid #ebebeb;
  }
  &.pricingHistory {
    .panel-body {
      padding: 0px;
    }
  }
}
.no-record-accordion button {
  cursor: default;
  &::before {
    display: none;
  }
}
.faq-accordion {
	.card {
		margin-bottom: 20px;
		border: 1px solid #ECECEC;
		.card-header {
			padding: 1rem 1.5rem;
			border-bottom: 1px solid #ECECEC;
		}
		.card-body {
			padding: 1.25rem 1.5rem;
		}
		.btn {
			font-size: 1.125rem;
			font-weight: 500;
			color: #152549;
			padding: 0;
		}
	}
	.accordion-group:last-child {
		margin-bottom: 0;
	}
}
.page-link {
  color: #22a2d7;
  border: 1px solid #ebebeb;
  &:hover {
    color: #ff9e2d;
    background-color: #f3f3f3;
  }
  &:focus {
    box-shadow: none;
  }
}
.page-item.active {
  .page-link {
    background-color: #22a2d7;
    border-color: #22a2d7;
  }
}
.page-item.disabled {
  .page-link {
    color: #a0a9be;
  }
}
.whatsapp-icon {
  position: fixed;
  bottom: 50px;
  right: 50px;
  height: 60px;
  width: 60px;
  line-height: 82px;
  background-color: #25D366;
  text-align: center;
  border-radius: 100%;
  font-size: 14px;
  z-index: 99;
}
.footer {
  background-color: #222f4a;
  color: rgba(255, 255, 255, 0.8);
  a {
    &:not(:hover) {
      color: rgba(255, 255, 255, 0.8);
    }
  }
}
.inner-footer {
  padding-top: 60px;
  padding-bottom: 50px;
  font-weight: 300;
  ul {
    li {
      padding: 8px 0px;
    }
  }
}
.footer-copyright {
  color: #878b94;
  padding: 15px 0px;
  background-color: #1b2230;
}
.social-icons {
  a {
    font-size: 1.125rem;
    color: #878b94;
    margin: 0 0.5rem;
    &:hover {
      color: #fff;
    }
  }
}
.collapse {
  -webkit-transition: color 0.3s ease-in-out, background 0.3s ease-in-out,
    opacity 0.3s ease-in-out, border 0.3s ease-in-out,
    font-weight 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out,
    opacity 0.3s ease-in-out, border 0.3s ease-in-out,
    font-weight 0.3s ease-in-out;
}
.owl-theme {
  .owl-nav {
    [class*="owl-"] {
      -webkit-transition: color 0.3s ease-in-out, background 0.3s ease-in-out,
        opacity 0.3s ease-in-out, border 0.3s ease-in-out,
        font-weight 0.3s ease-in-out;
      transition: color 0.3s ease-in-out, background 0.3s ease-in-out,
        opacity 0.3s ease-in-out, border 0.3s ease-in-out,
        font-weight 0.3s ease-in-out;
    }
  }
}
.navbar-nav {
  > li {
    > a {
      &:before {
        -webkit-transition: width 0.3s ease-in-out;
        transition: width 0.3s ease-in-out;
      }
    }
  }
}
.navbar-right {
  > li {
    > a {
      &:before {
        -webkit-transition: width 0.3s ease-in-out;
        transition: width 0.3s ease-in-out;
      }
    }
  }
}
@media all and (-ms-high-contrast: none) {
  .form-label-group {
    > label {
      display: none;
    }
    input {
      &:-ms-input-placeholder {
        color: #a6aab5;
      }
    }
    textarea {
      &:-ms-input-placeholder {
        color: #a6aab5;
      }
    }
  }
}
@media (-ms-high-contrast: active) {
  .form-label-group {
    > label {
      display: none;
    }
    input {
      &:-ms-input-placeholder {
        color: #a6aab5;
      }
    }
    textarea {
      &:-ms-input-placeholder {
        color: #a6aab5;
      }
    }
  }
}

// Others
.custom-validation {
  display: block !important;
}
// .input-group {
//   flex-wrap: unset !important;
// }

// input-group-custom
.input-group-custom {
  position: relative;
}
.input-group-append-custom {
  position: absolute;
  top: 0px;
  right: 0px;
}
.input-group-custom .form-control {
  padding-right: 50px;
}
.input-group-text-custom {
  background-color: #f0f6ff;
  border: 1px solid #dfe7f3;
  color: #96a9c6;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.input-group-custom
  .form-control:focus
  ~ .input-group-append-custom
  .input-group-text-custom {
  border-left: 1px solid #22a2d7;
}

/*Loader*/
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.6);
}
.loader-logo {
  z-index: 5;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  height: 50px;
}
.loader-container .spinner {
  margin: 0 auto;
  width: 70px;
  height: 50px;
  text-align: center;
  font-size: 20px;
}
.loader-container .spinner > div {
  background-color: #333;
  height: 100%;
  width: 6px;
  margin: 0 3px 0 0;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.loader-container .spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.loader-container .spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.loader-container .spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.loader-container .spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
#map_wrapper {
  height: 400px;
}
